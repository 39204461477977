import React from "react";
import bannerimg1 from '../../assets/images/bannerimg1.png';
import bannerimg2 from '../../assets/images/bannerimg2.png';
import Top from './Top';
import Footer from './Footer';

function WebsiteLayout() {

    return (
        <div id="main-wrapper flex-column">
            <Top />
            <div class="body-wrapper overflow-hidden bg-white p-0 m-0 rounded-0">
                <section class="hero-section text-bg-light position-relative overflow-hidden mb-0 mb-lg-5">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-xl-6">
                                <div class="hero-content my-5 my-xl-0">
                                    <h6 class="d-flex align-items-center gap-2 fs-4 fw-semibold mb-3" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                                        <i class="ti ti-rocket text-secondary fs-6"></i>Kick start
                                        your business with us!
                                    </h6>
                                    <h1 class="fw-bolder mb-7" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
                                        Manage  <span class="primaryColor">Entire Business Processes</span> with Mojave ERP

                                    </h1>
                                    <p class="fs-5 mb-5 text-dark fw-normal" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                                        Supports Automation & Processes in Finance, Education, Banking, Manufacturing, Supply Chain, Services,
                                        Human Resource, Procurement, Real Estate and more...
                                    </p>
                                    <div class="d-sm-flex align-items-center gap-3" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">
                                        <a class="btn btn-primary px-5 py-6 btn-hover-shadow d-block mb-3 mb-sm-0" href="#">Book a free demo!</a>
                                        <a class="btn btn-outline-primary d-block scroll-link px-7 py-6" href="#">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 d-none d-xl-block">
                                <div class="hero-img-slide position-relative bg-light p-4 rounded">
                                    <div class="d-flex flex-row">
                                        <div>
                                            <div class="banner-img-1 slideup">
                                                <img src={bannerimg1} alt="matdash-img" class="img-fluid" />
                                            </div>
                                            <div class="banner-img-1 slideup">
                                                <img src={bannerimg1} alt="matdash-img" class="img-fluid" />
                                            </div>
                                        </div>
                                        <div>
                                            <div class="banner-img-2 slideDown">
                                                <img src={bannerimg2} alt="matdash-img" class="img-fluid" />
                                            </div>
                                            <div class="banner-img-2 slideDown">
                                                <img src={bannerimg2} alt="matdash-img" class="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default WebsiteLayout;
