import React from "react";
import logo from '../../assets/images/logo.svg';
import imagemain from '../../assets/images/demo-main.jpg';
import megaddbg from '../../assets/images/backgrounds/mega-dd-bg.jpg';

function Top() {

    return (
        <header class="header">
            <nav class="navbar navbar-expand-lg py-0">
                <div class="container">
                    <a class="navbar-brand me-0 py-0" href="/#">
                        <img src={logo} alt="img-fluid" style={{height:'41px'}} />
                    </a>
                    <button class="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="ti ti-menu-2 fs-9"></i>
                    </button>
                    <button class="navbar-toggler border-0 p-0 shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <i class="ti ti-menu-2 fs-9"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav align-items-center mb-2 mb-lg-0 ms-auto">
                            <li class="nav-item dropdown hover-dd mega-dropdown">
                                <a class="nav-link dropdown-toggle" href="/#" role="button" aria-expanded="false">
                                    Products
                                    <span class="d-flex align-items-center">
                                        <i class="ti ti-chevron-down"></i>
                                    </span>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-animate-up p-4">
                                    <div class="row justify-content-center my-4">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        School
                                                    </h6>
                                                </div>
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        Church
                                                    </h6>
                                                </div>
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        Accounting &amp; Payroll
                                                    </h6>
                                                </div>
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        Core HR
                                                    </h6>
                                                </div>
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">Banking</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-lg-12">
                                            <div class="row justify-content-between">
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        CRM &amp; Manufacturing
                                                    </h6>
                                                </div>
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        POS (Supermarket, Pharmacy, Hotel)
                                                    </h6>
                                                </div>
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        Fleet &amp; City Bus
                                                    </h6>
                                                </div>
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        Hospital
                                                    </h6>
                                                </div>
                                                <div class="col">
                                                    <div class="border d-block rounded-1 mb-2 position-relative lp-demos-box overflow-hidden">
                                                        <img src={imagemain} alt="matdash-img" class="img-fluid" />
                                                        <a href="/#" class="btn btn-primary lp-demos-btn fs-2 p-1 position-absolute top-50 start-50 translate-middle">Live
                                                            Preview</a>
                                                    </div>
                                                    <h6 class="mb-0 text-center fw-bolder fs-3">
                                                        Real Estate
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </li>
                            <li class="nav-item dropdown hover-dd mega-dropdown pages-dropdown">
                                <a class="nav-link dropdown-toggle" href="/#" role="button" aria-expanded="false">
                                    Solutions
                                    <span class="d-flex align-items-center">
                                        <i class="ti ti-chevron-down"></i>
                                    </span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-nav  dropdown-menu-animate-up py-0 overflow-hidden">
                                    <div class="position-relative">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="p-4 pb-3">

                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="position-relative">
                                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                                    <div class="bg-primary-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                                        <iconify-icon icon="solar:chat-line-bold-duotone" class="fs-7 text-primary"></iconify-icon>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="mb-0">Software Development</h6>
                                                                        <span class="fs-11 d-block text-body-color">Rich, user friendly &amp; robust</span>
                                                                    </div>
                                                                </a>
                                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                                    <div class="bg-warning-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                                        <iconify-icon icon="solar:phone-calling-rounded-bold-duotone" class="fs-7 text-secondary"></iconify-icon>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="mb-0">Technology Consulting</h6>
                                                                        <span class="fs-11 d-block text-body-color">Business process re-engineering</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="position-relative">
                                                            <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                                    <div class="bg-secondary-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                                        <iconify-icon icon="solar:bill-list-bold-duotone" class="fs-7 text-secondary"></iconify-icon>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="mb-0">Database Development</h6>
                                                                        <span class="fs-11 d-block text-body-color">Sql, NoSql, Big Data &amp; Cloud</span>
                                                                    </div>
                                                                </a>
                                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                                    <div class="bg-danger-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                                        <iconify-icon icon="solar:letter-bold-duotone" class="fs-7 text-danger"></iconify-icon>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="mb-0">Mobile App Development</h6>
                                                                        <span class="fs-11 d-block text-body-color">Business critical solutions for Android &amp; iPhone</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4 d-none d-lg-flex">
                                                <img src={megaddbg} alt="mega-dd" class="img-fluid mega-dd-bg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item dropdown hover-dd pages-dropdown">
                                <a class="nav-link dropdown-toggle" href="/#" role="button" aria-expanded="false">
                                    Company
                                    <span class="d-flex align-items-center">
                                        <i class="ti ti-chevron-down"></i>
                                    </span>
                                </a>
                                <div class="dropdown-menu  dropdown-menu-animate-up py-0 overflow-hidden">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div className='p-4'>
                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                    <div class="bg-secondary-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                        <iconify-icon icon="solar:bill-list-bold-duotone" class="fs-7 text-secondary"></iconify-icon>
                                                    </div>
                                                    <div>
                                                        <h6 class="mb-0">Our Story</h6>
                                                        {/* <span class="fs-11 d-block text-body-color">New messages arrived</span> */}
                                                    </div>
                                                </a>
                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                    <div class="bg-secondary-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                        <iconify-icon icon="solar:bill-list-bold-duotone" class="fs-7 text-secondary"></iconify-icon>
                                                    </div>
                                                    <div>
                                                        <h6 class="mb-0">Partnerships</h6>
                                                        {/* <span class="fs-11 d-block text-body-color">New messages arrived</span> */}
                                                    </div>
                                                </a>
                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                    <div class="bg-secondary-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                        <iconify-icon icon="solar:bill-list-bold-duotone" class="fs-7 text-secondary"></iconify-icon>
                                                    </div>
                                                    <div>
                                                        <h6 class="mb-0">Referrals</h6>
                                                        {/* <span class="fs-11 d-block text-body-color">New messages arrived</span> */}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div className='p-4'>
                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                    <div class="bg-secondary-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                        <iconify-icon icon="solar:bill-list-bold-duotone" class="fs-7 text-secondary"></iconify-icon>
                                                    </div>
                                                    <div>
                                                        <h6 class="mb-0">Brand Kit</h6>
                                                        {/* <span class="fs-11 d-block text-body-color">New messages arrived</span> */}
                                                    </div>
                                                </a>
                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                    <div class="bg-secondary-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                        <iconify-icon icon="solar:bill-list-bold-duotone" class="fs-7 text-secondary"></iconify-icon>
                                                    </div>
                                                    <div>
                                                        <h6 class="mb-0">Community</h6>
                                                        {/* <span class="fs-11 d-block text-body-color">New messages arrived</span> */}
                                                    </div>
                                                </a>
                                                <a href="/#" class="d-flex align-items-center pb-9 position-relative">
                                                    <div class="bg-secondary-subtle rounded round-48 me-3 d-flex align-items-center justify-content-center">
                                                        <iconify-icon icon="solar:bill-list-bold-duotone" class="fs-7 text-secondary"></iconify-icon>
                                                    </div>
                                                    <div>
                                                        <h6 class="mb-0">Training</h6>
                                                        {/* <span class="fs-11 d-block text-body-color">New messages arrived</span> */}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* <li class="nav-item">
                                    <a class="nav-link" aria-current="page" href="/#" target="_blank">Documentation</a>
                                </li> */}
                            <li class="nav-item">
                                <a class="nav-link" aria-current="page" href="/#">Support</a>
                            </li>
                            <li class="nav-item ms-2">
                                <a class="btn btn-primary btn-hover-shadow" href="/#">Login</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Top;
