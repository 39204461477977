import React from "react";
import logo from "../../assets/images/mojave_logo_square.png";
import { Link } from "react-router-dom";
import moment from "moment";

function Footer() {

    return (
        <footer class="footer-part pt-7 pb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <div class="text-center">
                            <a href="#">
                                <img src={logo} alt="matdash-img" class="img-fluid" style={{width:'64px'}} />
                            </a>
                            <p class="mb-0 mt-0 text-dark"> All rights reserved &copy; 2018 - {moment().format('YYYY')}. </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
